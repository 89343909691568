import {Box, Button, Modal, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {PetFoundActions} from 'store/modules/pet-found-report/actions'
import {AuthActions} from 'store/modules/authentication/actions'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 2
}

export const SuccessReport = props => {
  const {open, setOpen} = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(state => state.authentication.user)
  const {code} = useParams()

  const handleClose = () => {
    dispatch(PetFoundActions.resetPetFound())

    if (setOpen) setOpen(false)

    navigate(`/pet-found?code=${code}`, {replace: true})
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex flex-col items-center ">
            <div className="flex items-center">
              <Typography className="!ml-2 !text-base">Pet Sighting Recorded</Typography>
            </div>
            <p className="mt-4 text-start">
              Thank you for reporting the found pet. Your contribution is invaluable in helping reunite pets
              with their owners.
            </p>
            <Button className="!mt-4" variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
