import {AttachmentPreview} from '../AttachmentPreview'
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import {NavigationMap} from './NavigationMap'
import ImageIcon from '@mui/icons-material/Image'
import {socketClient} from 'client/socket.client'
import {useState} from 'react'
import {useEffect} from 'react'
import {CircularProgress} from '@mui/material'

const ImagePreview = ({media}) => {
  const [url, setUrl] = useState()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (!media) return
    setLoading(true)
    const query = {key: media.path, noRedirect: false}
    socketClient
      .service('content')
      .find({query})
      .then(result => {
        if (result?.url) {
          setUrl(result?.url)
        }
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }, [media])

  return loading ? (
    <CircularProgress />
  ) : url ? (
    <img width="100%" src={url} onClick={() => window.open(url, '_blank')} />
  ) : (
    <ImageIcon sx={{cursor: 'pointer', mt: 1, width: '64px', height: '64px'}} color="disabled" />
  )
}
export const SimpleMessage = props => {
  const {data, user} = props

  const openImageInNewTab = media => {
    if (media) {
      const query = {key: media.path, noRedirect: false}
      socketClient
        .service('content')
        .find({query})
        .then(result => {
          if (result?.url) {
            window.open(result?.url, '_blank')
          }
        })
        .catch(console.log)
    }
  }
  return (
    <div className="flex flex-col justify-end ">
      <div
        key={data._id}
        className={`flex items-center ${
          data.from === user?._id ? 'received justify-start ' : 'sent justify-end '
        } w-4/5`}
      >
        <div className={`message`}>
          {data.message}
          {data?.automated && data?.metadata?.scan?.location?.length == 2 && (
            <NavigationMap
              lat={data?.metadata?.scan?.location[1]}
              lng={data?.metadata?.scan?.location[0]}
              id={`navigation-map-${data?._id}`}
            />
          )}

          {!!data?.attachments?.length &&
            data?.attachments.map((item, index) => <ImagePreview media={item} key={index} />)}
        </div>
        {data.from !== user?._id && (
          <DoneAllIcon sx={{fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888'}} />
        )}
      </div>
    </div>
  )
}
