export const actions = {
  HYDRATE: 'HYDRATE',
  PAGE: 'PAGE',
  RECEIVED: 'RECEIVED',
  SEEN: 'SEEN',
  UPDATE: 'UPDATE',
  AUTOMATED: 'AUTOMATED',
  DISABLE_AUTOMATED: 'DISABLE_AUTOMATED',

  hydrate(data) {
    return {
      type: actions.HYDRATE,
      data
    }
  },
  hydrateNextPage(data) {
    return {
      type: actions.PAGE,
      data
    }
  },
  received(data) {
    return {
      type: actions.RECEIVED,
      data
    }
  },
  seen(data) {
    return {
      type: actions.SEEN,
      data
    }
  },
  update(data) {
    return {
      type: actions.UPDATE,
      data
    }
  },
  automated(data) {
    return {
      type: actions.AUTOMATED,
      data
    }
  },
  disableAutomated() {
    return {
      type: actions.DISABLE_AUTOMATED,
    }
  }
}

export const MessagingActions = actions
