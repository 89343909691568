import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import {useContext, useState} from 'react'
import {useEffect} from 'react'
import {Button} from '@mui/material'
import {QRCodeSVG} from 'qrcode.react'
import {Scanner} from '@yudiel/react-qr-scanner'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useLocation, useNavigate} from 'react-router-dom'

export const HandoffQRMessage = props => {
  const {data, user} = props
  const [handOffString, setHandOffString] = useState(null)
  const [openScanner, setOpenScanner] = useState(false)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const code = queryParams.get('code')

  useEffect(() => {
    if (data?.metadata?.action === 'handoffScheduled' && data?.metadata?.handoff?.handoffs?.length) {
      const handoff = data?.metadata?.handoff?.handoffs[0]
      setHandOffString(handoff?.signature)
    }
  }, [data])

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind === 'videoinput')
          if (videoDevices.length === 0) {
            handleToast('No camera devices found', 'error')
          }
        })
        .catch(err => handleToast(err?.message, 'error'))
    } else {
      handleToast('No camera devices found', 'error')
    }
  }, [])

  const handleToast = (message, status) => {
    setSeverity(status)
    showToast(message ? message : 'Something Went wrong')
  }

  return (
    <div className="flex flex-col justify-end ">
      <div
        key={data._id}
        className={`flex items-center ${
          data.from === user?._id ? 'received justify-start ' : 'sent justify-end '
        } w-4/5`}
      >
        <div className={`message`}>
          {data.message}
          {handOffString && <QRCodeSVG value={handOffString} className="mx-auto my-2" />}
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{mb: 1, fontWeight: 'bold'}}
            onClick={() => setOpenScanner(true)}
            disabled={data?.metadata?.actionDisable}
            fullWidth
          >
            Scan their QR
          </Button>
          {openScanner && (
            <Scanner
              onScan={_result => {
                setOpenScanner(false)
                if (_result?.length) {
                  console.log(_result[0].rawValue)
                  const handoffkey = _result[0].rawValue

                  localStorage.setItem('handOffQRKey', handoffkey)
                  navigate(`/pet-handoff/${code}`)
                }
              }}
              onError={e => {
                setOpenScanner(false)
                if (e.name === 'NotFoundError') {
                  handleToast('No camera devices found or allow camera permission. Try again', 'error')
                } else {
                  handleToast(e.message, 'error')
                }
              }}
            />
          )}
        </div>

        {data.from !== user?._id && (
          <DoneAllIcon sx={{fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888'}} />
        )}
      </div>
    </div>
  )
}
