import {AttachmentPreview} from '../AttachmentPreview'
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import {NavigationMap} from './NavigationMap'
import ImageIcon from '@mui/icons-material/Image'
import {socketClient} from 'client/socket.client'
import {useSelector} from 'react-redux'
import {Button} from '@mui/material'
import {useRef} from 'react'
import {SheltersDialog} from '../dialog/SheltersDialog'

export const RevealedLocationMessage = props => {
  const {data, user} = props
  const dialogRef = useRef(null)
  const petReport = useSelector(state => state?.petFoundReport?.petReport)

  const handleOpen = () => {
    if (dialogRef.current) {
      dialogRef.current.open()
    }
  }

  const sendDisableActionsRequest = () => {
    socketClient
      .service('messaging')
      .metadata({
        _id: data._id,
        ...data.metadata,
        actionDisable: true
      })
      .then(result => {
        console.log(result)
      })
      .catch(console.log)
  }

  const handleConfirmRevealPoint = () => {
    const payload = {
      reportId: petReport?.report?._id,
      reunitePointConfirmed: true
    }

    socketClient
      .service('pet-finder')
      .confirmReunitePoint(payload)
      .then(result => {
        sendDisableActionsRequest()
      })
      .catch(console.log)
  }

  return (
    <div className="flex flex-col justify-end ">
      <div
        key={data._id}
        className={`flex items-center ${
          data.from === user?._id ? 'received justify-start ' : 'sent justify-end '
        } w-4/5`}
      >
        <div className={`message`}>
          {data.message}
          {data?.automated && petReport?.report?.location?.length == 2 && (
            <NavigationMap
              id="reunite-map"
              lat={petReport?.report?.location[1]}
              lng={petReport?.report?.location[0]}
            />
          )}
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{my: 2}}
              onClick={handleConfirmRevealPoint}
              disabled={data?.metadata?.actionDisable}
              fullWidth
            >
              Confirm reunite point
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              sx={{mb: 1}}
              onClick={() => handleOpen()}
              disabled={data?.metadata?.actionDisable}
              fullWidth
            >
              Choose from near by shelters
            </Button>
          </div>
        </div>
        {data.from !== user?._id && (
          <DoneAllIcon sx={{fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888'}} />
        )}
      </div>
      <SheltersDialog ref={dialogRef} />
    </div>
  )
}
