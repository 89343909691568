import {AttachmentPreview} from '../AttachmentPreview'
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import {NavigationMap} from './NavigationMap'
import ImageIcon from '@mui/icons-material/Image'
import {socketClient} from 'client/socket.client'
import {useContext, useRef, useState} from 'react'
import {useEffect} from 'react'
import {Button, CircularProgress} from '@mui/material'
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useSelector} from 'react-redux'
import {SheltersDialog} from '../dialog/SheltersDialog'

export const ReportedMessage = props => {
  const {data, user} = props
  const {getCurrentLocation} = useGeoCurrentLocation()
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const petReport = useSelector(state => state?.petFoundReport?.petReport)
  const dialogRef = useRef(null)

  const handleOpen = () => {
    if (dialogRef.current) {
      dialogRef.current.open()
    }
  }

  const [location, setLocation] = useState([])

  useEffect(() => {
    askForLocation()
  }, [])

  const handleToast = (message, status) => {
    setSeverity(status)
    showToast(message)
  }

  const askForLocation = () => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        setLocation(location)
      })
      .catch(e => {
        console.log(e)
        handleToast(e, 'error')
      })
  }

  const openImageInNewTab = media => {
    if (media) {
      const query = {key: media.path, noRedirect: false}
      socketClient
        .service('content')
        .find({query})
        .then(result => {
          if (result?.url) {
            window.open(result?.url, '_blank')
          }
        })
        .catch(console.log)
    }
  }

  const sendDisableActionsRequest = () => {
    socketClient
      .service('messaging')
      .metadata({
        _id: data._id,
        ...data.metadata,
        actionDisable: true
      })
      .then(result => {
        console.log(result)
      })
      .catch(console.log)
  }

  const handleConfirmRevealPoint = () => {
    const payload = {
      reportId: petReport?.report?._id,
      reunitePointSuggested: location
    }

    socketClient
      .service('pet-finder')
      .requestReunitePoint(payload)
      .then(result => {
        sendDisableActionsRequest()
      })
      .catch(console.log)
  }

  return (
    <div className="flex flex-col justify-end ">
      <div
        key={data._id}
        className={`flex items-center ${
          data.from === user?._id ? 'received justify-start ' : 'sent justify-end '
        } w-4/5`}
      >
        <div className={`message`}>
          {data.message}
          {data?.automated && data?.metadata?.scan?.location?.length == 2 && (
            <NavigationMap
              lat={data?.metadata?.scan?.location[1]}
              lng={data?.metadata?.scan?.location[0]}
              id={`navigation-map-${data?._id}`}
            />
          )}

          {!!data?.attachments?.length &&
            data?.attachments.map((item, index) => (
              <ImageIcon
                key={index}
                sx={{cursor: 'pointer', mt: 1, width: '64px', height: '64px'}}
                // color="disabled"
                onClick={() => openImageInNewTab(item)}
              />
            ))}
          <div className="flex">
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{fontSize: '10px', mr: '8px'}}
              onClick={handleConfirmRevealPoint}
              disabled={data?.metadata?.actionDisable}
              fullWidth
            >
              Request reunite
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              sx={{fontSize: '10px', ml: '8px'}}
              onClick={() => handleOpen()}
              disabled={data?.metadata?.actionDisable}
              fullWidth
            >
              Choose from near by shelters
            </Button>
          </div>
          <Button
            variant="contained"
            sx={{
              fontSize: '10px',
              my: '8px',
              backgroundColor: 'lightgray',
              color: 'black',
              borderColor: 'gray',
              '&:hover': {
                backgroundColor: 'lightgray',
                borderColor: 'lightgray'
              },
              textTransform: 'none'
            }}
            disabled={data?.metadata?.actionDisable}
            fullWidth
          >
            Report Sighting only
          </Button>
        </div>
        {data.from !== user?._id && (
          <DoneAllIcon sx={{fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888'}} />
        )}
      </div>
      <SheltersDialog ref={dialogRef} method="requestReunitePoint" data={data} />
    </div>
  )
}
