import {useEffect, useMemo, useState} from 'react'
import {PetFoundActions} from 'store/modules/pet-found-report/actions'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router-dom'
import {Button, TextField} from '@mui/material'
import appLogo from 'images/app_logo.png'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext} from 'react'
import {socketClient} from 'client/socket.client'
import {useMediaUpload} from 'hooks/useMediaUpload'
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'
import axios from 'axios'
import {AuthActions} from 'store/modules/authentication/actions'
import {LoadingDialog} from 'components/LodingDialog'
import {ImageUploader} from '../scan-report/component/ImageUploader'
import {MapLocationFinder} from '../scan-report/component/MapLocationFinder'

export const PetHandOff = () => {
  const petReport = useSelector(state => state?.petFoundReport?.petReport)
  const user = useSelector(state => state?.authentication?.user)
  const {setSeverity, showToast} = useContext(SnackbarContext)

  const [selectedImages, setSelectedImages] = useState(null)
  const [location, setLocation] = useState(['-74.006', '40.7128'])
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {loading: fileUploading, uploadMedia} = useMediaUpload()
  const {getCurrentLocation} = useGeoCurrentLocation()
  const {code} = useParams()

  const handleClose = () => {
    dispatch(PetFoundActions.resetPetFound())
    dispatch(AuthActions.resetAuth())
    navigate(-1)
  }

  const handleToast = (message, status) => {
    setSeverity(status)
    showToast(message)
  }

  const getReport = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/pet-found?code=${code}`)
      .then(result => {
        dispatch(PetFoundActions.setPetFoundReport(result?.data))
      })
      .catch(e => handleToast('Pet Report not found', 'error'))
    setLoading(false)
  }

  useEffect(() => {
    if (!code) handleClose()

    if (!petReport) getReport()

    askForLocation()
  }, [])

  const askForLocation = () => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        setLocation(location)
      })
      .catch(e => {
        console.log(e)
        handleToast(e, 'error')
      })
  }

  const isError = () => {
    if (!selectedImages?.file) {
      handleToast('Please Select Image.', 'error')
      return true
    }

    if (!note) {
      handleToast('Please provide Additional Details & Observations', 'error')
      return true
    }

    if (location.length === 0) {
      handleToast('Yout current location is required', 'error')
      return true
    }

    if (!petReport?.report) {
      handleToast('Report not found. Please scan again', 'error')
      return true
    }

    if (!user) {
      handleToast('Please login', 'error')
      return true
    }

    return false
  }

  const handleSubmit = async () => {
    if (isError()) {
      return
    }

    const signature = window.localStorage.getItem('handOffQRKey')

    if (!signature) {
      handleToast('Hand Off Signature missing, scan again', 'error')
      setLoading(false)
      return
    }

    setLoading(true)
    const media = await uploadMedia(selectedImages.file)
    if (!media || media?.error) {
      handleToast('Failed to upload image. Try again')
      setLoading(false)
      return
    }

    const params = {
      reportId: petReport?.report?._id,
      toLocation: location,
      note,
      signature
    }
    if (Array.isArray(media) && media?.length) {
      params.media = media[0]?._id
    }

    socketClient
      .service('pet-handoffs')
      .handoff(params)
      .then(handleResult)
      .catch(error => {
        console.log(error)
        handleToast(error?.message, 'error')
        setLoading(false)
      })
  }

  const handleResult = result => {
    if (result) {
      navigate(`/pet-found?code=${code}`)
      localStorage.removeItem('handOffQRKey')
    }
  }

  window.addEventListener('popstate', function (event) {
    if (event.state) {
      if (user && !user?.roles?.includes('admin')) {
        dispatch(AuthActions.resetAuth())
      }
      navigate(-1)
    }
  })

  return (
    <div className="w-full flex flex-col lg:mt-8 justify-center lg:justify-start items-center p-4">
      {loading && <LoadingDialog />}
      <img src={appLogo} alt="Noah'sArk" />
      <div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: '8px'
        }}
        className="flex flex-col p-4 lg:p-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3"
      >
        <p className="font-bold text-start pl-2 mb-2">Upload media of pet handoff</p>
        <p className="text-start text-sm text-slate-600 px-2">
          For the pet handoff, it would be great if you could take a clear, new photo of your pet using your
          camera, but no worries if not—just let us know!
        </p>
        <ImageUploader selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
        <p className="font-bold text-start pl-1 mt-8">Provide additional notes if any</p>
        <p className="text-start text-sm text-slate-600 mt-1">
          Share any extra information or observations regarding the lost pet handoff here.
        </p>
        <TextField
          placeholder="Description"
          className="!mt-2"
          value={note}
          onChange={e => setNote(e.target.value)}
          multiline
          rows={2}
          fullWidth
        />

        <p className="font-bold text-start mt-8">Location for Pet handoff verification</p>
        <div>
          <p className="text-start text-sm text-slate-600 mt-2">
            We've automatically detected your current location, which is where you handoff the pet. If the
            location is incorrect, please ensure your device's location services are enabled. You can manually
            adjust and drop a pin to provide a different location if needed.
          </p>
          <div className="w-full mt-1">
            <MapLocationFinder
              lat={location[1]}
              lng={location[0]}
              height="400px"
              onLocationChange={location => setLocation(location)}
            />
          </div>
        </div>
        <Button
          variant="contained"
          className="!mt-4"
          onClick={handleSubmit}
          disabled={fileUploading || loading}
        >
          Submit
        </Button>
      </div>
    </div>
  )
}
