import { find } from 'lodash'
import {actions} from './actions'

const initialState = {
  messages: [],
  automated: false,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.HYDRATE:
      return {...state, messages: action.data}

    case actions.PAGE:
      return {
        ...state, 
        messages: [...action.data, ...state.messages]
      }
    case actions.RECEIVED:
      const managed = find(state.messages, m => m._id === action.data._id)
     
      if (managed) {
        return {...state}
      }
      return {...state, messages: [...state.messages, action.data]}

    case actions.SEEN:
      const _state = state.messages.map((item) => {
        if (action?.data?.messages?.includes(item?._id)) {
          return {...item, seen: true}
        }
        return item
      })

      return {...state, messages: [..._state]}

    case actions.UPDATE : 
      const messages = state.messages.map((item) => {
        if (action?.data?._id === item?._id) {
          return action?.data
        }
        return item
      })
      return {...state, messages: [...messages]}

    case actions.AUTOMATED :   
      return {...state, messages: [...state.messages, action.data], automated: true}

    case actions.DISABLE_AUTOMATED : 
      return {...state, automated: false}
    default:
      return state
  }
}

export default reducer
