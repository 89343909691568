import { Questionnaire } from "./Questionnaire"
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useMemo } from "react";

const Answer = (props) => {
  const {questionnaire, id} = props

  const answer = useMemo(() => {
    const _answer = questionnaire?.find(i => i?.question === id) 
    return _answer?.answer
  }, [questionnaire, id])

  return (
    <p>{answer}</p>
  )
}
export const QuestionnairResponseMessage = (props) => {
  const {data, user} = props

  return (
    <div className='flex flex-col justify-end'>
      <div key={data._id} className={`flex items-center ${data.from === user?._id ? 'received' : 'sent'} w-4/5`}>
        <div className={`message`}>
          {data.message} 

          {/* Questionnair Responce*/}
          <div className="mt-4">
            {!!data?.metadata?.questions?.length && data?.metadata?.questions.map((item) => (
              <div key={item?.id} className="my-2">
                <p className="font-bold">{item?.question}</p>
                <Answer questionnaire={data?.metadata?.scan?.questionnaire} id={item?.id}/>
              </div>
            ))}
          </div>
        </div>
        {data.from !== user?._id  && 
          <DoneAllIcon 
            sx={{ fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888' }} 
          />
        }
      </div>
    </div>
  )
}