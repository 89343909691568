import {combineReducers} from 'redux'
import AuthReducer from './authentication/reducer'
import PetFoundReportReducer from './pet-found-report/reducer'
import MessagingReducer from './messaging/reducer'

export const rootReducer = () => {
  return combineReducers({
    authentication: AuthReducer,
    petFoundReport: PetFoundReportReducer,
    messaging: MessagingReducer
  })
}
