import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material"
import { socketClient } from "client/socket.client"
import { has } from "lodash"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"

export const Questionnaire = (props) => {
  const {message} = props
  const [questions, setQuestions] = useState(props?.message?.metadata?.questions.map((i) => ({...i, answer: false})))
  const petReport = useSelector(state => state?.petFoundReport?.petReport)

  const handleSubmit = () => {
    const payload = {
      reportId: petReport?.report?._id,
      questionnaire: questions.map((item) => ({question: item.id, answer: item?.answer ? 'Yes' : 'No'}))
    }

    socketClient
      .service('pet-finder')
      .questionnairesResponse(payload)
      .then((result) => {
        updateMessageMetadata()
      }).catch(console.log)
  }

  const updateMessageMetadata = () => {
    socketClient
      .service('messaging')
      .metadata({
        _id: message?._id,
        ...message?.metadata,
        actionDisable: true
      })
      .then(console.log)
      .catch(console.log)
  }

  return (
    <div className="mt-4">
      {!!questions.length && questions.map((item) => (
        <div key={item?.id} className="flex font-bold items-center my-2 justify-between">
          <span>{item?.question}</span> 
          <FormGroup>
            <FormControlLabel 
              control={<Switch  onChange={(e) => {
                setQuestions((prev) => {
                  const newState = prev.map((q) => {
                    if (q?.id === item?.id) {
                      return { ...q, answer: e.target.checked }
                    }
                    return q
                  })
                  return newState
                })
              }}/>} 
              disabled={props?.message?.metadata?.actionDisable}
              label={item?.answer ? 'Yes' : 'No'} 
            />
          </FormGroup>
        </div>
      ))}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => handleSubmit()}
        disabled={props?.message?.metadata?.actionDisable}
        fullWidth
      >
        Submit Answer
      </Button>
    </div>
  )
}