import {useEffect, useMemo, useState} from 'react'
import {ReportActionModal} from './modal/ReportActionModal'
import {PetFoundActions} from 'store/modules/pet-found-report/actions'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import {ImageUploader} from './component/ImageUploader'
import {Button, TextField} from '@mui/material'
import appLogo from 'images/app_logo.png'
import {SnackbarContext} from 'components/snackbar/SnackbarContext'
import {useContext} from 'react'
import {socketClient} from 'client/socket.client'
import moment from 'moment'
import {useMediaUpload} from 'hooks/useMediaUpload'
import {useGeoCurrentLocation} from 'hooks/useGeoCurrentLocation'
import axios from 'axios'
import {findIndex} from 'lodash'
import {AuthActions} from 'store/modules/authentication/actions'
import {MapLocationFinder} from './component/MapLocationFinder'
import {LoadingDialog} from 'components/LodingDialog'
import EnrollNotifyModal from './EnrollNotifyModal'
import {SuccessReport} from './SuccessReport'
import {Chat} from 'modules/messaging/Chat'

export const CreateScanReport = () => {
  const petReport = useSelector(state => state?.petFoundReport?.petReport)
  const user = useSelector(state => state?.authentication?.user)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const pageLocation = useLocation()

  const [selectedImages, setSelectedImages] = useState(null)
  const [open, setOpen] = useState(true)
  const [isConfirm, setConfirm] = useState(true)
  const [location, setLocation] = useState(['-74.006', '40.7128'])
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [openEnroll, setOpenEnroll] = useState(false)
  const [openSuccessModal, setSuccessModal] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {loading: fileUploading, uploadMedia} = useMediaUpload()
  const {getCurrentLocation} = useGeoCurrentLocation()
  const {code} = useParams()

  const shouldAllowMessaging = useMemo(() => {
    return !!user?._id
  }, [user])

  const handleClose = () => {
    dispatch(PetFoundActions.resetPetFound())
    dispatch(AuthActions.resetAuth())
    setOpen(false)
    navigate(`/pet-found?code=${code}`, {replace: true})
  }

  const handleToast = (message, status) => {
    setSeverity(status)
    showToast(message)
  }

  const getReport = () => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/pet-found?code=${code}`)
      .then(result => {
        dispatch(PetFoundActions.setPetFoundReport(result?.data))
      })
      .catch(e => handleToast('Pet Report not found', 'error'))
    setLoading(false)
  }

  useEffect(() => {
    if (petReport?.report?.scans && petReport?.report?.scans?.length > 0 && user) {
      const index = findIndex(petReport?.report?.scans, i => i?.by?._id === user?._id)
      setConfirm(index !== -1 ? false : true)
    }
  }, [petReport])

  useEffect(() => {
    if (!code) handleClose()

    if (!petReport) getReport()

    askForLocation()
  }, [])

  const askForLocation = () => {
    getCurrentLocation()
      .then(location => {
        console.log(location)
        setLocation(location)
      })
      .catch(e => {
        console.log(e)
        handleToast(e, 'error')
      })
  }

  const isError = () => {
    if (!selectedImages?.file) {
      handleToast('Please Select Image.', 'error')
      return true
    }

    if (!note) {
      handleToast('Please provide Additional Details & Observations', 'error')
      return true
    }

    if (location.length === 0) {
      handleToast('Yout current location is required', 'error')
      return true
    }

    if (!petReport?.report) {
      handleToast('Report not found. Please scan again', 'error')
      return true
    }

    if (!user) {
      handleToast('Please login', 'error')
      return true
    }

    return false
  }

  const handleSubmit = async () => {
    if (isError()) {
      return
    }

    setLoading(true)
    const media = await uploadMedia(selectedImages.file)
    if (!media || media?.error) {
      handleToast('Failed to upload image. Try again')
      setLoading(false)
      return
    }

    if (Array.isArray(media) && media?.length > 0) {
      const payload = {
        reportId: petReport?.report?._id,
        location,
        note,
        helpful: true,
        media: media[0]?._id,
        time: moment().utc()
      }
      socketClient
        .service('pet-finder')
        .report(payload)
        .then(handleResult)
        .catch(error => {
          console.log(error)
          setLoading(false)
          handleToast(error?.message, 'error')
        })
    }
  }

  const handleResult = result => {
    if (result) {
      //Below code temporary committed and show success model
      setSuccessModal(true)

      // socketClient.service('enroll').find({query: {user: user?._id}})
      //   .then((result) => {
      //     result?.data?.length ? setSuccessModal(true) : setOpenEnroll(true)
      //     setLoading(false)
      //   })
      //   .catch(e => {
      //     console.log(e)
      //     setSuccessModal(true)
      //   })
    }
  }

  window.addEventListener('popstate', function (event) {
    if (event.state) {
      if (user && !user?.roles?.includes('admin')) {
        dispatch(AuthActions.resetAuth())
      }
      navigate(-1)
    }
  })

  // return !user ? (
  //   <Navigate to="/authentication/login" state={{from: pageLocation.pathname}} />
  // ) :
  return (
    <div className="w-full flex flex-col lg:mt-8 justify-center lg:justify-start items-center p-4">
      {loading && <LoadingDialog />}
      <img src={appLogo} alt="Noah'sArk" />
      <div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: '8px'
        }}
        className="flex flex-col p-4 lg:p-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3"
      >
        <p className="font-bold text-start pl-2 mb-2">Take Photo / Upload Photo:</p>
        <p className="text-start text-sm text-slate-600 px-2">
          Please provide a clear photo of the found pet. High-quality photos help us identify the pet more
          easily.
        </p>
        <ImageUploader selectedImages={selectedImages} setSelectedImages={setSelectedImages} />
        <p className="font-bold text-start pl-1 mt-8">Provide Additional Details & Observations:</p>
        <p className="text-start text-sm text-slate-600 mt-1">
          Your comments play a crucial role in reuniting the pet with its owner.
        </p>
        <TextField
          placeholder="Provide Additional Details & Observations"
          className="!mt-2"
          value={note}
          onChange={e => setNote(e.target.value)}
          multiline
          rows={2}
          fullWidth
        />

        <p className="font-bold text-start mt-8">Location for Pet Owner Notification:</p>
        <div>
          <p className="text-start text-sm text-slate-600 mt-2">
            Please ensure your device's location services are enabled. You can manually adjust and drop a pin
            to provide a different location if needed.
          </p>
          <div className="w-full mt-1">
            <MapLocationFinder
              lat={location[1]}
              lng={location[0]}
              height="400px"
              onLocationChange={location => setLocation(location)}
            />
          </div>
        </div>
        <Button
          variant="contained"
          className="!mt-4"
          onClick={handleSubmit}
          disabled={fileUploading || loading}
        >
          Submit
        </Button>
      </div>
      {!isConfirm && (
        <ReportActionModal open={open} onClose={handleClose} handleConfirm={() => setConfirm(true)} />
      )}
      <SuccessReport open={openSuccessModal} onClose={() => setSuccessModal(!openEnroll)} />
      <EnrollNotifyModal open={openEnroll} onClose={() => setOpenEnroll(!openEnroll)} />
      {shouldAllowMessaging ? <Chat participant={petReport?.pet?.user?._id} /> : null}
    </div>
  )
}
