import {forwardRef, useEffect, useImperativeHandle, useState} from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from '@mui/material'
import axios from 'axios'
import {filter} from 'lodash'
import {socketClient} from 'client/socket.client'
import {useSelector} from 'react-redux'

const ShelterItem = props => {
  const {item, selected, onClick} = props
  return (
    <div className="flex flex-col cursor-pointer" onClick={() => onClick(item)}>
      <div className="flex items-center">
        <Checkbox checked={item?.id === selected?.id} />
        <div>
          <Typography fontSize="16px" fontWeight="bold">
            {item?.name}
          </Typography>
          <Typography fontSize="14px">{item?.address}</Typography>
          <Typography fontSize="14px">
            {item?.website ? `${item?.website}` : ''} {item?.phone ? `- ${item?.phone}` : ''}
          </Typography>
        </div>
      </div>
      <Divider sx={{my: 1}} />
    </div>
  )
}

export const SheltersDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shelters, setShelters] = useState([])
  const [filtered, setFiltered] = useState([])
  const [selected, setSelected] = useState(null)
  const petReport = useSelector(state => state?.petFoundReport?.petReport)

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false)
  }))

  useEffect(() => {
    if (open) {
      getShelters()
    }
  }, [open])

  const onSearch = e => {
    if (e.target.value.length) {
      const filtered = filter(shelters, i => i.name.toLowerCase().includes(e.target.value.toLowerCase()))
      setFiltered(filtered)
    } else {
      setFiltered(shelters)
    }
  }

  const getShelters = async () => {
    try {
      setLoading(true)
      const result = await axios.get('https://api.noahsarkdonate.com/api/submerchant/shelters', {
        headers: {
          ['x-api-key']: 'd9b6c2d35fd963f9e79b81797dc9b23afac342a36378a1e33407840bbb1a8a0e'
        }
      })
      setLoading(false)
      if (result?.data?.length) {
        const _shelters = result.data.map(item => {
          return {
            id: item?.id,
            name: item?.legalName,
            address: `${item?.addressLine1}, ${item?.addressLine2 ? `${item?.addressLine2},` : ''} ${
              item?.city
            }-${item?.postalCode}, ${item?.state}`,
            website: item.website,
            phone: item?.busPhoneNo,
            logo: item?.logo
          }
        })

        setShelters(_shelters)
        setFiltered(_shelters)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const sendDisableActionsRequest = () => {
    const {data} = props
    if (!data) {
      setOpen(false)
      return
    }

    socketClient
      .service('messaging')
      .metadata({
        _id: data._id,
        ...data.metadata,
        actionDisable: true
      })
      .then(result => {
        console.log(result)
      })
      .catch(console.log)
    setOpen(false)
  }

  const handleResult = () => {
    sendDisableActionsRequest()
  }

  const handleContinue = () => {
    if (props?.method == 'requestReunitePoint') {
      const payload = {
        reportId: petReport?.report?._id,
        reuniteShelter: '' + selected?.id
      }
      socketClient
        .service('pet-finder')
        .requestReunitePoint(payload)
        .then(result => {
          handleResult()
        })
        .catch(console.log)
    } else {
      const payload = {
        reportId: petReport?.report?._id,
        reunitePointConfirmed: true,
        reuniteShelter: '' + selected?.id
      }
      socketClient
        .service('pet-finder')
        .confirmReunitePoint(payload)
        .then(result => {
          handleResult()
        })
        .catch(console.log)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <TextField placeholder="Search shelter" onChange={onSearch} size="small" autoFocus fullWidth />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div className="flex justify-center">
            {' '}
            <CircularProgress />{' '}
          </div>
        ) : (
          <div className="flex flex-col w-96">
            {filtered.map((item, index) => (
              <ShelterItem key={index} item={item} selected={selected} onClick={item => setSelected(item)} />
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          color="primary"
          size="small"
          disabled={loading}
        >
          Close
        </Button>
        <Button
          onClick={() => handleContinue()}
          variant="contained"
          color="primary"
          size="small"
          disabled={loading}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
})
