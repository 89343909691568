import React, {useState, useEffect} from 'react'
import {Autocomplete, TextField} from '@mui/material'
import {socketClient} from 'client/socket.client'

const SheltersAutocomplete = ({id = null, onChange, className = '', disabled = false, fullWidth = false}) => {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(null)

  // Fetch options when component mounts or if there's no id
  useEffect(() => {
    socketClient
      .service('shelters')
      .getExternalShelters()
      .then(result => setOptions(result))
      .catch(console.log)
  }, [])

  useEffect(() => {
    if (id && options.length && id !== 0) {
      const selectedValue = options.find(i => i.id === id)
      setValue(selectedValue)
    }
  }, [id, options])

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option.legalName || ''} // Ensure it's a string
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
        console.log(newValue)
        newValue?.id ? onChange(newValue.id) : onChange(0)
      }}
      filterOptions={(options, state) =>
        options.filter(option => option.legalName?.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      renderInput={params => <TextField {...params} label="Assign Shelter" variant="outlined" fullWidth />}
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  )
}

export default SheltersAutocomplete
