import {useEffect, useMemo, useRef, useState} from 'react'
import {ReactComponent as ChatIcon} from '../../asset/chat.svg'
import {ReactComponent as CloseIcon} from '../../asset/close.svg'
import {socketClient} from 'client/socket.client'
import {find, reverse, throttle} from 'lodash'
import {isDifferentDay, mask, maskEmail} from './chat.util'
import beep from '../../asset/sound/message.mp3'
import {Separator} from './Separator'
import './chat.css'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {SimpleMessage} from './components/SimpleMessage'
import {QuestionnairMessage} from './components/QuestionnairMessage'
import {QuestionnairResponseMessage} from './components/QuestionnairResponseMessage'
import {MessagingActions} from 'store/modules/messaging/actions'
import {useSelector, useDispatch} from 'react-redux'
import {RevealedLocationMessage} from './components/ReveledLocationMessage'
import {HandoffQRMessage} from './components/HandoffQRMessage'
import {ReportedMessage} from './components/ReportedMessage'

const PER_PAGE = 20

export const Chat = ({participant: talkTo}) => {
  const bottomRef = useRef()
  const soundRef = useRef()
  const dispatch = useDispatch()
  const messages = useSelector(state => state?.messaging?.messages)
  const automatedMessage = useSelector(state => state?.messaging?.automated)

  const [expanded, setExpanded] = useState(false)
  const [channel, setChannel] = useState('')
  const [participants, setParticipants] = useState([])
  const [hasPreviousPage, setHasPreviousPage] = useState(false)

  const [loading, setLoading] = useState(false)

  const [paginator, setPaginator] = useState({
    total: 0,
    limit: 0,
    skip: 0
  })

  const [message, setMessage] = useState('')

  useEffect(() => {
    if (bottomRef.current && bottomRef.current.scrollTop > 50) {
      bottomRef.current.scrollTop = bottomRef.current.scrollHeight
    }
  }, [messages])

  const handleHeadClick = e => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  const playBeep = () => {
    if (soundRef.current) {
      soundRef.current.play()
    } else {
      console.log('No audio source')
    }
  }

  const user = useMemo(() => {
    const _user = find(participants, p => p._id === talkTo)
    return {
      _id: _user?._id,
      name:
        _user?.firstName && _user?.lastName
          ? `${_user?.firstName} ${_user?.lastName}`
          : _user?.username
          ? _user.username
          : 'Unknown',
      email: maskEmail(_user?.email || ''),
      phone: mask(_user?.phone || '')
    }
  }, [participants, talkTo])

  useEffect(() => {
    join()
  }, [])

  const join = () => {
    socketClient.io.emit(
      'join',
      'messaging',
      {
        participant: talkTo
      },
      {},
      (error, result) => {
        if (error) {
          console.error(error)
          return
        }
        setChannel(result.channel)
        setParticipants(result.participants)
        hydrateMessages(result.channel)
      }
    )
  }

  const send = () => {
    if (!message) {
      return
    }
    socketClient.io.emit('send', 'messaging', {channel, message, to: talkTo}, {}, (error, result) => {
      if (error) {
        console.error(error)
        return
      }

      setMessage('')
    })
  }

  const hydrateMessages = (_channel, skip = 0) => {
    if (loading) {
      return
    }

    setLoading(true)
    const query = {
      channel: _channel,
      $populate: ['room', 'attachments'],
      $sort: {
        createdAt: -1
      },
      $limit: PER_PAGE
    }

    if (skip) {
      query.$skip = skip
    }

    socketClient.io.emit('find', 'messaging', query, (e, result) => {
      if (e) {
        console.log(`Could not load message history`, e)
        return
      }

      const {data, total, limit, skip} = result
      setPaginator({total, limit, skip})

      if (!data.length) {
        setHasPreviousPage(false)
        return
      }

      if (skip ? skip + limit < total : total > limit) {
        setHasPreviousPage(true)
      }

      if (skip) {
        dispatch(MessagingActions.hydrateNextPage(reverse(data)))
      } else {
        dispatch(MessagingActions.hydrate(reverse(data)))
      }
      setLoading(false)
    })
  }

  const handleScroll = e => {
    if (e.target.scrollTop === 0 && hasPreviousPage) {
      hydrateMessages(channel, paginator.skip + PER_PAGE)
    }
  }

  useEffect(() => {
    if (!expanded && automatedMessage) {
      setExpanded(true)
    }
  }, [automatedMessage])

  const renderMessage = (m, index) => {
    if (m?.metadata?.action === 'questionnaire') {
      return (
        <>
          {index !== 0 && isDifferentDay(messages[index - 1].createdAt, m.createdAt) && (
            <Separator key={`${m._id}-separator`} time={m.createdAt} />
          )}
          {!!m?.metadata?.scan?.questionnaire?.length ? (
            <QuestionnairResponseMessage index={index} data={m} user={user} />
          ) : (
            <QuestionnairMessage index={index} data={m} user={user} messages={messages} />
          )}
        </>
      )
    }

    if (m?.metadata?.action === 'revealed') {
      return (
        <>
          {index !== 0 && isDifferentDay(messages[index - 1].createdAt, m.createdAt) && (
            <Separator key={`${m._id}-separator`} time={m.createdAt} />
          )}
          <RevealedLocationMessage data={m} user={user} />
        </>
      )
    }

    if (m?.metadata?.action === 'handoffScheduled') {
      return (
        <>
          {index !== 0 && isDifferentDay(messages[index - 1].createdAt, m.createdAt) && (
            <Separator key={`${m._id}-separator`} time={m.createdAt} />
          )}
          <HandoffQRMessage data={m} user={user} />
        </>
      )
    }

    if (m?.metadata?.action === 'reported') {
      return (
        <>
          {index !== 0 && isDifferentDay(messages[index - 1].createdAt, m.createdAt) && (
            <Separator key={`${m._id}-separator`} time={m.createdAt} />
          )}
          <ReportedMessage data={m} user={user} />
        </>
      )
    }

    if (m?.metadata?.action === 'requested') {
      return <></>
    }

    return (
      <>
        {index !== 0 && isDifferentDay(messages[index - 1].createdAt, m.createdAt) && (
          <Separator key={`${m._id}-separator`} time={m.createdAt} />
        )}
        <SimpleMessage data={m} user={user} />
      </>
    )
  }

  return (
    <div className="chat-head" onClick={handleHeadClick}>
      <div className="icon-container">
        <ChatIcon height="25px" width="25px" className="icon" />
      </div>
      <div className={`chat-popup ${expanded ? 'visible' : ''}`} onClick={e => e.stopPropagation()}>
        <div className="chat-popup-body">
          <div className="chat-popup-header">
            <div className="header-avatar">
              <img
                src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
                alt=""
              />
            </div>
            <div className="header-info">
              <h4>{user?.name}</h4>
              <small>{user?.email || user?.phone}</small>
            </div>
            <div className="header-options">
              {/* <ThreeDots
                className="icon"
                style={{ transform: 'rotate(90deg)' }}
                height="18px"
                width="18px"
                onClick={() => setExpanded(false)}
              /> */}
              <CloseIcon
                style={{marginRight: 10}}
                height="20px"
                width="20px"
                onClick={() => {
                  setExpanded(false)
                  dispatch(MessagingActions.disableAutomated())
                }}
              />
            </div>
          </div>
          {expanded && (
            <div className="chat-popup-messages" ref={bottomRef} onScroll={handleScroll}>
              {!messages.length ? 'No messages' : messages.map(renderMessage)}
            </div>
          )}
          <div className="chat-popup-footer">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Write and hit enter.."
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    send()
                  }
                }}
              />
            </div>
            <AttachFileIcon sx={{my: 'auto', mr: 1, cursor: 'pointer'}} />
            <div className="send-btn" onClick={() => send()}>
              Send
            </div>
          </div>
        </div>
        <audio className="hidden" ref={soundRef} src={beep}></audio>
      </div>
    </div>
  )
}
