import axios from 'axios'
import {v4 as uuidv4} from 'uuid'

export const getLocationAddress = (latitude, longitude) => {
  const REVRSE_GEO_CODE_URL = 'https://maps.googleapis.com/maps/api/geocode/json'
  const GOOGLE_MAP_API_KEY = 'AIzaSyCKUD0ZyB5keBxxDPzvuwD_ssMcZg6hWws'

  return axios.get(`${REVRSE_GEO_CODE_URL}?key=${GOOGLE_MAP_API_KEY}&latlng=${latitude},${longitude}`)
}

export function getBrowserId() {
  let key = localStorage.getItem('browserId')
  if (!key) {
    key = uuidv4()
    localStorage.setItem('browserId', key)
    return key
  }
  return key
}
