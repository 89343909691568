import { Questionnaire } from "./Questionnaire"
import '../chat.css'
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const QuestionnairMessage = (props) => {
  const {data, user} = props

  return (
    <div className='flex flex-col justify-end'>
      <div key={data._id} className={`flex items-center ${data.from === user?._id ? 'received' : 'sent'} w-4/5`}>
        <div className={`message`}>
          {data.message} 

          {/* Questionnair */}
          {(data?.metadata?.action === 'questionnaire' && 
            !!data?.metadata?.questions?.length ) && 
            <Questionnaire message={data} />
          }
        </div>
        {data.from !== user?._id  && 
          <DoneAllIcon 
            sx={{ fontSize: 14, ml: 1, color: data.seen ? '#0288d1' : '#888' }} 
          />
        }
      </div>
    </div>
  )
}