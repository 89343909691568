import './App.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {Auth} from './modules/auth/Auth'
import {Dashboard} from './modules/dashboard/Dashboard'
import {Invitations} from 'modules/invites/Invitations'
import {PetDiet} from 'modules/pet-diet/PetDiet'
import {Pets} from 'modules/pets/Pets'
import {FlaggedReport} from 'modules/flagged-content/report'
import {ModerationDashboard} from 'modules/flagged-content/moderation'
import {Provider} from 'react-redux'
import {Theme} from './Theme.js'
import store from 'store/store'
import {SnackbarContextProvider} from 'components/snackbar/SnackbarProvider'
import {IntroSlides} from 'modules/intro-slides'
import {LostPet} from 'modules/lost-pet'
import {ModerationReport} from 'modules/moderation-report/ModerationReport'
import {PetFoundPage} from 'modules/pet-found'
import {SetupBasicDetail} from 'modules/auth/SetupProfileDetail'
import {ScanReport} from 'modules/pet-found/scan-report'
import {Shelter} from 'modules/shelter'
import {Store} from 'modules/store/Store'
import {InviteVerify} from 'modules/auth/InviteVerify'
import {Enroll} from 'modules/pet-found/enroll'
import {UserDetail} from 'modules/users/UserDetail'
import {AddFundingSource} from 'modules/enroll/AddFundingSource'
import {Wallet} from 'modules/wallet'
import PrivacyPolicy from 'modules/privacy-policy'
import TermsConditions from 'modules/terms-conditions'
import {Referral} from 'modules/referral'
import {PetHandOff} from 'modules/pet-found/PetHandOff'

function App() {
  return (
    <Provider store={store}>
      <div className="App h-full">
        <Theme>
          <SnackbarContextProvider>
            <BrowserRouter>
              <Routes>
                <Route index path="/" element={<Dashboard />} />
                <Route path="/authentication/*" element={<Auth />} />
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/user/:id" element={<UserDetail />} />
                <Route path="/invitations/*" element={<Invitations />} />
                <Route path="/pet-diet/*" element={<PetDiet />} />
                <Route path="/pets/*" element={<Pets />} />
                <Route path="/lost-pet" element={<LostPet />} />
                <Route path="/flagged-report" element={<FlaggedReport />} />
                <Route path="/moderation-dashboard" element={<ModerationDashboard />} />
                <Route path="/intro-slides" element={<IntroSlides />} />
                <Route path="/moderation/*" element={<ModerationReport />} />
                <Route path="/pet-found" element={<PetFoundPage />} />
                <Route path="/basic-detail" element={<SetupBasicDetail />} />
                <Route path="/scan-report/*" element={<ScanReport />} />
                <Route path="/shelters/*" element={<Shelter />} />
                <Route path="/store/*" element={<Store />} />
                <Route path="/invites/conversion/:code" element={<InviteVerify />} />
                <Route path="/enroll" element={<Enroll />} />
                <Route path="/enroll/create-funding-source" element={<AddFundingSource />} />
                <Route path="/wallet/*" element={<Wallet />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-conditions" element={<TermsConditions />} />
                <Route path="/referral/:code" element={<Referral />} />
                <Route path="/pet-handoff/:code" element={<PetHandOff />} />
              </Routes>
            </BrowserRouter>
          </SnackbarContextProvider>
        </Theme>
      </div>
    </Provider>
  )
}

export default App
