import {useEffect, useState} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './nav-map.css';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import {renderToString} from 'react-dom/server';

export const NavigationMap = (props) => {
  const {lat = 0, lng = 0, id} = props;
  const [pinState, setPinState] = useState({lat: 0, lng: 0});

  useEffect(() => {
    setPinState({lat, lng});
  }, [lat, lng]);

  useEffect(() => {
    const map = L.map(id, {
      center: [pinState.lat, pinState.lng],
      zoom: 13,
      dragging: false,
      zoomControl: false,
      scrollWheelZoom: false,
      doubleClickZoom: false,
      boxZoom: false,
      keyboard: false,
      tap: false,
      touchZoom: false
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map);

    // Custom marker icon
    const customIcon = L.divIcon({
      html: renderToString(<FmdGoodRoundedIcon style={{ color: 'red', fontSize: '2rem' }} />),
      iconSize: [24, 24],
      className: 'custom-icon',
    });

    const marker = L.marker([pinState.lat, pinState.lng], { icon: customIcon }).addTo(map);

    map.on('click', () => {
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${pinState.lat},${pinState.lng}`;
      window.open(googleMapsUrl, '_blank');
    });

    return () => {
      map.remove();
    };
  }, [pinState]);

  return (
    <div style={{width: '100%', height: '150px', position: 'relative'}}>
      <div id={id} style={{width: '100%', height: '150px', borderRadius: '10px'}} />
    </div>
  );
};
